import React, { useState } from 'react'
import { NakedLayout } from '../../components/Layout'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import LineThroughLabel from '../../components/Label/LineThroughLabel'
import MarkerLabel from '../../components/Label/MarkerLabel'
import NavBar from '../../components/Navbar'
import { renderJoinWaitingListModal } from '../../components/Form/JoinWaitingList/fabric'
import { LEAD_TYPES } from '../../components/Form/JoinWaitingList'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import QuestionApp from '../../app/QuestionApp'
import Footer from '../../components/Footer'

import BlobOne from '../../static/inline-svg/blobOne.svg'
import confettiSvg from '../../static/eastwood/confetti.svg'
import confetti2Svg from '../../static/eastwood/confetti2.svg'
import paperSvg from '../../static/eastwood/paper.svg'
import digitalClockSvg from '../../static/eastwood/digitalClock.svg'
import backpackSvg from '../../static/eastwood/backpack.svg'
import tubesSvg from '../../static/eastwood/tubes.svg'

import mobilePng from '../../static/index/mobile-content.png'
import desktopPng from '../../static/index/desktop-content.png'

import ArrowSvg from '../../static/inline-svg/tryItArrow.svg'
import ROUTES from '../../config/routes'

const FirstCover = ({ id }) => {
  const { t } = useTranslation('common')

  return (
    <div
      id={id}
      className={
        'flex w-full flex-col py-16 md:py-0 md:min-h-full bg-small-circles relative overflow-hidden'
      }
    >
      <div
        className={'absolute top-0 left-0 right-0 bottom-0'}
        style={{ backgroundColor: 'hsla(0,0%,100%,.86)' }}
      />

      <div className={'mb-auto'} />

      <div
        className={
          'max-w-2xl lg:max-w-3xl xl:max-w-4xl 2xl:max-w-6xl mx-auto w-full px-2 lg:px-8 relative'
        }
      >
        <BlobOne
          className={
            'hidden md:block fill-current text-blue-200 select-none absolute w-96 inline-block top-0 right-0 z-10 -mr-32 -mt-12'
          }
        />
        <BlobOne
          className={
            'hidden md:block fill-current text-green-200 select-none absolute w-96 rotate-45 inline-block top-0 right-0 z-10 -mr-32 -mt-12'
          }
        />

        <img
          src={confettiSvg}
          alt={'Confetti'}
          className={
            'hidden md:block select-none absolute w-96 rotate-45 inline-block z-20 top-0 right-0 z-10 -mr-64 -mt-12'
          }
        />
        <img
          src={confettiSvg}
          alt={'Confetti'}
          className={
            'hidden md:block select-none absolute w-96 rotate-120 inline-block z-20 top-0 right-0 z-10 -mr-40 mt-52'
          }
        />
        <img
          src={confetti2Svg}
          alt={'Confetti'}
          className={
            'hidden md:block select-none absolute w-32 rotate-120 inline-block z-20 top-0 right-0 z-10 -mr-40 mt-52'
          }
        />
        <img
          src={paperSvg}
          alt={'Paper'}
          className={
            'hidden md:block select-none absolute w-24 inline-block top-0 right-0 z-20 -mr-18 mt-14'
          }
        />
        <img
          src={digitalClockSvg}
          alt={'Digital Clock'}
          className={
            'hidden md:block select-none absolute w-24 rotate-12 inline-block top-0 right-0 z-20 -mr-6 -mt-12'
          }
        />

        <BlobOne
          className={
            'hidden md:block fill-current text-green-200 select-none absolute w-96 rotate-45 inline-block top-0 left-0 z-10 -ml-32 -mt-6'
          }
        />

        <img
          src={confettiSvg}
          alt={'Confetti'}
          className={
            'hidden md:block select-none absolute w-96 -rotate-45 inline-block z-20 top-0 left-0 z-10 -ml-40 mt-48'
          }
        />
        <img
          src={confettiSvg}
          alt={'Confetti'}
          className={
            'hidden md:block select-none absolute w-96 rotate-45 inline-block z-20 top-0 left-0 z-10 -ml-64 mt-12'
          }
        />
        <img
          src={backpackSvg}
          alt={'Backpack'}
          className={
            'hidden md:block select-none absolute w-80 inline-block top-0 left-0 z-20 -ml-32 -mt-12'
          }
        />
        <img
          src={tubesSvg}
          alt={'Tubes'}
          className={
            'hidden md:block select-none absolute w-20 -rotate-12 inline-block bottom-0 left-0 z-20 -ml-2 mb-28'
          }
        />

        <div
          className={'relative text-center py-12 bg-white z-30 border border-gray-100 rounded-3xl'}
        >
          <div className={'text-sm xl:text-base 2xl:text-lg mb-3 text-gray-800'}>
            A GCSE/A-levels <MarkerLabel isDanger>self-study</MarkerLabel> platform fostering{' '}
            <MarkerLabel isInfo>self-discipline</MarkerLabel> and{' '}
            <MarkerLabel isSuccess>mental focus</MarkerLabel>
          </div>

          <h1 className={'flex flex-col font-bold mb-4 xl:mb-8'}>
            <span className={'text-4xl md:text-6xl xl:text-7xl 2xl:text-8xl'}>
              Slash <LineThroughLabel isAnimated>thousands</LineThroughLabel>
            </span>
            <span className={'text-xl md:text-3xl xl:text-4xl 2xl:text-5xl'}>
              of pounds off private tuition costs
            </span>
          </h1>

          <div className={'xl:text-lg 2xl:text-xl mb-8 text-gray-800'}>
            and get better exam grades too!
          </div>

          <div className={'px-4 md:px-0'}>
            <button
              className={
                'btn btn-lg btn-primary transition duration-300 ease-in-out hover:bg-green-100 hover:shadow-leftBottomBlack hover:text-black mb-2 md:mb-0 w-full md:w-auto md:mr-2'
              }
              onClick={() => {
                renderJoinWaitingListModal()
              }}
            >
              {t('index.buttons.register-as-parent')}
            </button>

            <button
              className={
                'btn btn-lg transition duration-300 ease-in-out btn-light-bordered hover:shadow-leftBottomBlack w-full md:w-auto'
              }
              onClick={() => {
                renderJoinWaitingListModal({
                  leadType: LEAD_TYPES.LEAD_TYPE_TUTORS,
                })
              }}
            >
              {t('index.buttons.register-as-tutor')}
            </button>
          </div>
        </div>
      </div>

      <div className={'mt-auto'} />
    </div>
  )
}

const FeatureList = ({ items, classNames, iconClassNames }) => {
  return (
    <ul className={classnames('', classNames)}>
      {items.map((e, key) => (
        <li key={key} className={'mb-4'}>
          <FontAwesomeIcon
            className={classnames('mr-3 inline-block', iconClassNames)}
            icon={['fas', 'check']}
          />
          <span>{e}</span>
        </li>
      ))}
    </ul>
  )
}

const RoundedIcon = ({ className, children }) => {
  return (
    <div
      className={classnames(
        'flex items-center justify-center p-4 border-2 border-black rounded-full inline-block mx-auto w-24 h-24 mb-12',
        className,
      )}
    >
      {children}
    </div>
  )
}

const SecondCover = ({ id }) => {
  const [isShadowMobileImage, setShadowMobileImage] = useState(true)

  return (
    <div id={id} className={'overflow-hidden'}>
      <div className={'max-w-7xl mx-auto w-full py-16 lg:py-32 xl:py-64 px-2 lg:px-8 relative'}>
        <div className={'flex flex-col relative'}>
          <div className={'max-w-2xl mx-auto mb-12 xl:mx-0 xl:mb-0'}>
            <h2 className={'text-3xl lg:text-5xl font-bold whitespace-pre-wrap mb-12'}>
              Automated self-study{'\n'}content and guidance
            </h2>

            <FeatureList
              classNames={'text-gray-800 text-lg'}
              iconClassNames={'text-blue-500'}
              items={[
                'Best curated content, including theory and worked examples',
                'Written to the specifications of English exam boards',
                'Based on leading personal tutors’ best practice',
                'Fully automated step-by-step and level-to-level guidance',
              ]}
            />
          </div>

          <div
            className={'mx-auto xl:mx-0 xl:absolute xl:top-0 xl:right-0 xl:-mr-52 xl:-mt-24'}
            style={{ width: '740px', height: '640px' }}
          >
            <div className={'relative'}>
              <figure
                className={classnames(
                  'absolute top-0 left-0 z-10 transition duration-500 ease-in-out hover:scale-105',
                  {
                    'shadow-rightBottomBlack': isShadowMobileImage === true,
                  },
                )}
                style={{
                  top: '72px',
                  borderRadius: '37px',
                  height: '530px',
                }}
              >
                <img
                  src={mobilePng}
                  alt={'Academator Mobile'}
                  className={'max-h-full select-none'}
                />
              </figure>

              <figure
                onMouseEnter={() => setShadowMobileImage(false)}
                onMouseLeave={() => setShadowMobileImage(true)}
                role="presentation"
                className={classnames(
                  'absolute top-0 hover:z-20 transition duration-500 ease-in-out hover:scale-105',
                  {
                    'shadow-rightBottomBlack': isShadowMobileImage === false,
                  },
                )}
                style={{
                  left: '100px',
                  borderRadius: '23px',
                  height: '530px',
                }}
              >
                <img
                  src={desktopPng}
                  alt={'Academator Desktop'}
                  className={'max-h-full select-none'}
                />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const ThirdCover = ({ id }) => {
  return (
    <div id={id}>
      <div className={'max-w-7xl mx-auto w-full py-16 lg:py-32 xl:py-64 px-2 lg:px-8 relative'}>
        <h2 className={'text-center text-3xl lg:text-5xl font-bold whitespace-pre-wrap mb-32'}>
          Clever algorithm developing{'\n'}athlete grade self-discipline
        </h2>

        <div className={'flex flex-wrap'}>
          <div className={'w-full md:w-1/3 text-center mb-12'}>
            <RoundedIcon className={'bg-blue-200 shadow-rightBottomGreen200'}>
              <FontAwesomeIcon className={'text-3xl'} icon={['fal', 'water']} />
            </RoundedIcon>

            <div className={'px-4 text-gray-800 text-sm md:text-lg'}>
              One screen at a time, no distractions, no divergences
            </div>
          </div>

          <div className={'w-full md:w-1/3 text-center mb-12'}>
            <RoundedIcon className={'bg-red-200 shadow-rightBottomGreen200'}>
              <FontAwesomeIcon className={'text-3xl'} icon={['fal', 'brain']} />
            </RoundedIcon>

            <div className={'px-4 text-gray-800 text-sm md:text-lg'}>
              Multiple learning paths to choose from, so we understand how your brain works best
            </div>
          </div>

          <div className={'w-full md:w-1/3 text-center mb-12'}>
            <RoundedIcon className={'bg-yellow-200 shadow-rightBottomGreen200'}>
              <FontAwesomeIcon className={'text-3xl'} icon={['fal', 'chart-line']} />
            </RoundedIcon>

            <div className={'px-4 text-gray-800 text-sm md:text-lg'}>
              We’ll work around your personal style of thinking to deliver results
            </div>
          </div>

          <div className={'w-full md:w-1/3 text-center mb-12'}>
            <RoundedIcon className={'bg-green-200 shadow-rightBottomGreen200'}>
              <FontAwesomeIcon className={'text-3xl'} icon={['fal', 'stopwatch']} />
            </RoundedIcon>

            <div className={'px-4 text-gray-800 text-sm md:text-lg'}>
              The algorithm will minimise your time spend (machines ain’t no time wasters)
            </div>
          </div>

          <div className={'w-full md:w-1/3 text-center mb-12'}>
            <RoundedIcon className={'bg-orange-200 shadow-rightBottomGreen200'}>
              <FontAwesomeIcon className={'text-3xl'} icon={['fal', 'list-ol']} />
            </RoundedIcon>

            <div className={'px-4 text-gray-800 text-sm md:text-lg'}>
              Get on the leaderboards and you’ll be revered in the most remote corners of the globe
            </div>
          </div>

          <div className={'w-full md:w-1/3 text-center mb-12'}>
            <RoundedIcon className={'bg-gray-200 shadow-rightBottomGreen200'}>
              <FontAwesomeIcon className={'text-3xl'} icon={['fal', 'user-headset']} />
            </RoundedIcon>

            <div className={'px-4 text-gray-800 text-sm md:text-lg'}>
              If you’re stuck (meaning really really stuck), best tutors will be just a click away
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const FourthCover = ({ id }) => {
  return (
    <div id={id} className={'flex w-full flex-col bg-small-circles relative px-4'}>
      <div
        className={'absolute top-0 left-0 right-0 bottom-0'}
        style={{ backgroundColor: 'hsla(0,0%,100%,.76)' }}
      />

      <div
        className={
          'bg-white max-w-2xl mx-auto rounded-xl w-full my-64 py-4 px-8 relative flex flex-col align-center justify-center'
        }
        style={{ minHeight: '550px' }}
      >
        <div className={'absolute top-0 right-0 -mt-52 lg:-mr-24'}>
          <ArrowSvg className={'h-48 w-48 select-none'} />
        </div>

        <QuestionApp
          questionId={process.env.ACADEMATOR_HOME_QUESTION_ID}
          showDarkModeSwitch={false}
        />
      </div>
    </div>
  )
}

const IndexPage = () => {
  const { t } = useTranslation('common')

  return (
    <NakedLayout isHeightFull>
      <NavBar
        navChildClassnames={'max-w-7xl'}
        menuClassnames={'h-28'}
        navClassnames={classnames('md:fixed md:top-0 md:left-0 md:right-0 md:z-50')}
        leftItems={[
          {
            label: t('index.navbar.links.home'),
            href: ROUTES.home,
          },
          {
            label: t('index.navbar.links.our-story'),
            href: ROUTES.ourStory,
          },
        ]}
        rightItems={
          <button className={'btn btn-primary'} onClick={() => renderJoinWaitingListModal()}>
            {t('index.buttons.register')}
          </button>
        }
      />

      <Helmet>
        <title>{t('index.seo.title')}</title>
      </Helmet>

      <FirstCover id={'step0'} />
      <SecondCover id={'step1'} />
      <ThirdCover id={'step2'} />
      <FourthCover id={'step3'} />

      <Footer className={'max-w-7xl px-8'} />
    </NakedLayout>
  )
}

export default IndexPage

import React from 'react'
import i18n from '../../../config/i18n'
import { renderFullScreenModal } from '../../Modal/fabric'
import { notificationSuccess } from '../../../utils/notification'
import JoinWaitingList, { JoinWaitingListType, LEAD_TYPE_PARENTS } from '.'

export const renderJoinWaitingListModal = (
  formOptions?: JoinWaitingListType,
  dialogOptions = {},
) => {
  const t = i18n.getFixedT(null, 'forms')

  const html = (
    <div className={'max-w-md mx-auto px-4'}>
      <div className={'mb-6 mt-32 text-center'}>
        <h1 className={'text-4xl mb-2 font-bold'}>{t('join-waiting-list.modal.title')}</h1>
        <p className={'text-gray-700 text-lg'}>{t('join-waiting-list.modal.subtitle')}</p>
      </div>

      <JoinWaitingList
        leadType={LEAD_TYPE_PARENTS}
        onSuccess={() => {
          modal.current.close()
          notificationSuccess(t('join-waiting-list.message.success'))
        }}
        {...formOptions}
      />
    </div>
  )

  const modal = renderFullScreenModal({
    ...dialogOptions,
    html: html,
    modalProps: {
      focusTrapped: false,
    },
  })

  return modal
}

import React from 'react'
import classnames from 'classnames'
import ROUTES from '../../config/routes'
import { useTranslation } from 'react-i18next'
import Link from '../Link/GatsbyLink'
import lightSvg from '../../static/logo/light.svg'

const NavBarLogo = () => {
  const { t } = useTranslation('common')

  const versionLabelStyle = {
    right: '10px',
    top: '-15px',
    fontSize: '0.5em',
  }

  return (
    <Link to={ROUTES.home} className={'h-8 w-auto hidden lg:flex items-center justify-center'}>
      <img className="w-auto h-9 w-9 mr-2" src={lightSvg} alt="Logo" />

      <span className={classnames('font-bitter text-xl relative tracking-tight')}>
        <span>{t('website.name')}</span>

        <span className={'absolute font-light'} style={versionLabelStyle}>
          {t('website.version')}
        </span>
      </span>
    </Link>
  )
}

export default NavBarLogo

import React from 'react'

const Select = (props) => {
  // eslint-disable-next-line
  return (
    <select
      onChange={props.onChange}
      value={props.value}
      disabled={props.disabled}
      required={props.required}
      className={props.className}
    >
      {props.options.map((o) => {
        if (typeof o.options !== 'undefined') {
          //grouped select
          return (
            <optgroup label={o.label}>
              {o.options.map((sub) => (
                <option key={sub.value} value={sub.value}>
                  {sub.label}
                </option>
              ))}
            </optgroup>
          )
        }

        // normal select
        return (
          <option key={o.value} value={o.value}>
            {o.label}
          </option>
        )
      })}
    </select>
  )
}

export default Select

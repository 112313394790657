import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { SolidRow, SolidError, SIZES, SolidFloatingLabelInput } from '../../Input/solid'
import LoadingButton from '../../Button/LoadingButton'
import { useTranslation } from 'react-i18next'
import api from '../../../apis'

export const LEAD_TYPE_PARENTS = 'parents'
export const LEAD_TYPE_TUTORS = 'tutors'

export const LEAD_TYPES = {
  LEAD_TYPE_PARENTS,
  LEAD_TYPE_TUTORS,
}

export type JoinWaitingListType = {
  onSuccess: () => void
  leadType: 'parents' | 'tutors'
}

const JoinWaitingList = ({ onSuccess, leadType }: JoinWaitingListType) => {
  const { t } = useTranslation('forms')

  const [isSubmitting, setSubmitting] = useState(false)

  const [name, setName] = useState('')
  const [nameError, setNameError] = useState('')
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setSubmitting(true)

    try {
      await api.auth.postJoinWaitingList(name, email, leadType)
      setSubmitting(false)
      setName('')
      setEmail('')

      onSuccess()
    } catch (error) {
      const status = error.response.status

      if (status === 422) {
        setSubmitting(false)

        return error.handle422({
          email: setEmailError,
          name: setNameError,
        })
      }

      error.handleDefault()
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <SolidRow>
        <SolidFloatingLabelInput
          label={t('join-waiting-list.name.label')}
          size={SIZES.LARGE}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setName(e.target.value)
            setNameError('')
          }}
          isInvalid={nameError.length > 0}
          disabled={isSubmitting}
          placeholder={t('join-waiting-list.name.placeholder')}
        />

        {nameError && <SolidError>{nameError}</SolidError>}
      </SolidRow>

      <SolidRow>
        <SolidFloatingLabelInput
          label={t('join-waiting-list.email.label')}
          size={SIZES.LARGE}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(e.target.value)
            setEmailError('')
          }}
          isInvalid={emailError.length > 0}
          disabled={isSubmitting}
          required={true}
          type={'email'}
          placeholder={t('join-waiting-list.email.placeholder')}
        />

        {emailError && <SolidError>{emailError}</SolidError>}
      </SolidRow>

      <LoadingButton
        type={'submit'}
        submitting={isSubmitting}
        className={'btn btn-primary btn-lg w-full'}
        label={t('join-waiting-list.submit.label')}
      />
    </form>
  )
}

JoinWaitingList.propTypes = {
  onSuccess: PropTypes.func,
  leadType: PropTypes.oneOf([LEAD_TYPE_PARENTS, LEAD_TYPE_TUTORS]),
}

JoinWaitingList.defaultProps = {
  onSuccess: () => null,
}

export default JoinWaitingList

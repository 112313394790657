import React from 'react'

const Input = (props) => {
  return (
    <input
      onChange={props.onChange}
      value={props.value}
      type={props.type ? props.type : 'text'}
      placeholder={props.placeholder}
      disabled={props.disabled}
      required={props.required}
      className={props.className}
      autoComplete={props.autoComplete}
    />
  )
}

export default Input

import React from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

const NavBarMobileButton = ({ onClick }) => {
  const { t } = useTranslation('common')

  return (
    <button
      type="button"
      onClick={onClick}
      className={classnames({
        'inline-flex items-center justify-center p-2 rounded-md focus:outline-none': true,
        'transition delay-100 duration-300 ease-in-out': true,
        'text-gray-400': true,
      })}
      aria-expanded="false"
    >
      <span className="sr-only">{t('navbar.sr-only.open-main-menu')}</span>

      <svg
        className="block h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M4 6h16M4 12h16M4 18h16"
        />
      </svg>

      <svg
        className="hidden h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </button>
  )
}

export default NavBarMobileButton

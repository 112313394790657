import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Link from '../Link/GatsbyLink'

const NavBarMenu = ({ items }) => {
  return (
    <div className="flex space-x-4">
      {items.map((e, key) => (
        <Link
          key={key}
          getProps={({ isCurrent }) => {
            return {
              className: classnames('px-3 py-2 rounded-md text-sm font-medium', {
                'text-gray-400 hover:text-black': isCurrent === false,
                'bg-gray-100 text-black': isCurrent,
              }),
            }
          }}
          to={e.href}
        >
          {e.label}
        </Link>
      ))}
    </div>
  )
}

NavBarMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
}

export default NavBarMenu

import React from 'react'
import classnames from 'classnames'
import Input from '../Input'
import TextArea from '../TextArea'
import Select from '../Select'
import Checkbox from '../Checkbox'
import InputRow from '../InputRow'
import InputLabel from '../InputLabel'
import InputError from '../InputError'
import InputHelper from '../InputHelper'

const SMALL = 'small'
const NORMAL = 'normal'
const LARGE = 'large'

export const SIZES = { SMALL, NORMAL, LARGE }

const commonClasses = [
  'block',
  'w-full',
  'text-black',
  'font-firaSans',
  'rounded-md',
  'focus:outline-none',
  'placeholder-gray-300',
  'focus:ring-0',
  'border-transparent',
]

const validClasses = 'bg-gray-100 focus:bg-gray-50 focus:border-gray-200'
const invalidClasses = 'bg-red-50 focus:bg-red-50 focus:border-red-200'
const successClasses = 'bg-lime-50 focus:bg-lime-50 focus:border-lime-200'

export const SolidInput = (props) => {
  return (
    <Input
      {...props}
      className={classnames(
        commonClasses,
        {
          'py-4 px-4 text-lg h-16': props.size === NORMAL || props.size === undefined,
          'py-6 px-6 text-2xl h-24': props.size === LARGE,
          [validClasses]: props.isInvalid !== true && props.isSuccess !== true,
          [invalidClasses]: props.isInvalid === true,
          [successClasses]: props.isSuccess === true && props.isInvalid !== true,
        },
        props.className,
      )}
    />
  )
}

export const SolidTextArea = (props) => {
  return (
    <TextArea
      {...props}
      className={classnames(
        commonClasses,
        {
          'py-4 px-4 text-lg': props.size === NORMAL || props.size === undefined,
          'py-6 px-6 text-2xl': props.size === LARGE,
          [validClasses]: props.isInvalid !== true && props.isSuccess !== true,
          [invalidClasses]: props.isInvalid === true,
          [successClasses]: props.isSuccess === true && props.isInvalid !== true,
        },
        props.className,
      )}
    />
  )
}

export const SolidFloatingLabelInput = (props) => {
  return (
    <div
      className={classnames('floating-input relative', {
        'floating-input-large': props.size === LARGE,
        'floating-input-empty-placeholder': props.placeholder === props.label, // do not show placeholder if it's equal to label
      })}
    >
      <SolidInput {...props} />

      <label
        className={classnames(
          'absolute text-gray-500 top-0 left-0 h-full pointer-events-none origin-left transition-all duration-100 ease-in-out',
          {
            'px-4 py-5': props.size === NORMAL || props.size === undefined,
            'text-lg px-6 py-9': props.size === LARGE,
          },
        )}
      >
        {props.label}
      </label>

      {props.rightContent !== undefined && (
        <div
          className={classnames('absolute top-0 right-0 h-full', {
            'px-4 py-5': props.size === NORMAL || props.size === undefined,
            'px-6 py-9': props.size === LARGE,
          })}
        >
          {props.rightContent}
        </div>
      )}
    </div>
  )
}

export const SolidFloatingLabelTextArea = (props) => {
  return (
    <div
      className={classnames('floating-input relative', {
        'floating-input-large': props.size === LARGE,
        'floating-input-empty-placeholder': props.placeholder === props.label, // do not show placeholder if it's equal to label
      })}
    >
      <SolidTextArea minRows={2} {...props} />

      <label
        className={classnames(
          'absolute text-gray-500 top-0 left-0 pointer-events-none origin-left transition-all duration-100 ease-in-out',
          {
            'px-4 py-5': props.size === NORMAL || props.size === undefined,
            'text-lg pl-6 pt-5': props.size === LARGE,
          },
        )}
      >
        {props.label}
      </label>
    </div>
  )
}

export const SolidSelect = (props) => {
  return (
    <Select
      {...props}
      className={classnames(
        commonClasses,
        {
          'py-4 px-4 text-lg h-16': props.size === NORMAL || props.size === undefined,
          'py-6 px-6 text-2xl h-24': props.size === LARGE,
          [validClasses]: props.isInvalid !== true && props.isSuccess !== true,
          [invalidClasses]: props.isInvalid === true,
          [successClasses]: props.isSuccess === true && props.isInvalid !== true,
        },
        props.className,
      )}
    />
  )
}

export const SolidLabel = (props) => {
  return <InputLabel {...props} className={classnames('text-gray-700 mb-1', props.className)} />
}

export const SolidError = (props) => {
  return <InputError {...props} className={classnames('text-red-500 mt-2', props.className)} />
}

export const SolidHelper = (props) => {
  return (
    <InputHelper
      {...props}
      className={classnames(
        'mt-2',
        {
          'text-gray-500': typeof props.isSuccess === 'undefined',
          'text-lime-600': props.isSuccess === true,
          'text-red-500': props.isSuccess === false,
        },
        props.className,
      )}
    />
  )
}

export const SolidRow = (props) => {
  return <InputRow {...props} className={classnames('text-left mb-5', props.className)} />
}

const checkboxClasses = [
  'rounded',
  'border-transparent',
  'focus:border-transparent',
  'focus:ring-1',
  'focus:ring-offset-2',
]

const checkboxValidClasses = 'bg-gray-100 focus:bg-gray-100 text-gray-700 focus:ring-gray-500'
const checkboxInvalidClasses = 'bg-red-50 focus:bg-red-50 text-red-200 focus:ring-red-200'
const checkboxSuccessClasses = 'bg-lime-50 focus:bg-lime-50 text-lime-200 focus:ring-lime-200'

export const SolidCheckbox = (props) => {
  return (
    <Checkbox
      {...props}
      className={classnames(
        checkboxClasses,
        {
          'py-2 px-2 h-4': props.size === NORMAL || props.size === undefined,
          'py-3 px-3 h-6': props.size === LARGE,
          [checkboxValidClasses]: props.isInvalid !== true && props.isSuccess !== true,
          [checkboxInvalidClasses]: props.isInvalid === true,
          [checkboxSuccessClasses]: props.isSuccess === true && props.isInvalid !== true,
        },
        props.className,
      )}
    />
  )
}

export const SolidCheckboxContainer = (props) => {
  return <InputLabel {...props} className={classnames('mb-5 block', props.className)} />
}

export const SolidCheckboxLabel = (props) => {
  return (
    <span
      {...props}
      className={classnames('ml-4 text-gray-700 tracking-tight cursor-pointer', props.className)}
    />
  )
}

import React from 'react'
import ROUTES from '../../config/routes'
import lightSvg from '../../static/logo/light.svg'
import Link from '../Link/GatsbyLink'

const NavBarMobileLogo = () => {
  return (
    <Link to={ROUTES.home} className={'block lg:hidden'}>
      <img className="h-11 w-11 w-auto" src={lightSvg} alt="Logo" />
    </Link>
  )
}

export default NavBarMobileLogo

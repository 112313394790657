import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import Link from '../Link/GatsbyLink'
import ROUTES from '../../config/routes'

import useAuth from '../../state/auth/useAuth'

const NavBarMobileMenu = ({ items }) => {
  const { t } = useTranslation('common')

  const { isLoaded, isGuest } = useAuth()

  return (
    <div className="px-2 pt-2 pb-3 space-y-1">
      {items.map((e, key) => (
        <Link
          key={key}
          getProps={({ isCurrent }) => {
            return {
              className: classnames('block px-3 py-2 rounded-md text-base font-medium', {
                'text-gray-400 hover:text-black': isCurrent === false,
                'bg-gray-100 text-black': isCurrent,
              }),
            }
          }}
          to={e.href}
        >
          {e.label}
        </Link>
      ))}

      {isLoaded && isGuest && (
        <>
          <hr className={'border-gray-200'} />

          <Link
            key={'login'}
            className={classnames('block px-3 py-2 rounded-md text-base font-medium', {
              'text-gray-400 hover:text-black': true,
            })}
            to={ROUTES.signin}
          >
            {t('navbar.loginLink')}
          </Link>

          <Link
            key={'signup'}
            className={classnames('block px-3 py-2 rounded-md text-base font-medium', {
              'text-gray-400 hover:text-black': true,
            })}
            to={ROUTES.signup}
          >
            {t('navbar.signupLink')}
          </Link>
        </>
      )}
    </div>
  )
}

NavBarMobileMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
}

export default NavBarMobileMenu

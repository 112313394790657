import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

type CircleButtonProps = {
  onClick: (e: React.MouseEvent<HTMLElement>) => void
  disabled: boolean
  className: string
  isBorderLess: boolean
  label: React.ReactNode
  children: React.ReactNode
}

const CircleButton = ({
  onClick,
  disabled,
  isBorderLess,
  className,
  label,
  children,
}: CircleButtonProps) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={classnames(
        'flex items-center justify-center cursor-pointer rounded-full box-content focus:outline-none',
        {
          'border border-gray-200 dark:border-gray-700': isBorderLess !== true,
          'w-9 h-9 sm:w-12 sm:h-12': true,
        },
        className,
      )}
    >
      {label ? label : children}
    </button>
  )
}

CircleButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  isBorderLess: PropTypes.bool,
  label: PropTypes.any,
  children: PropTypes.any,
}

export default CircleButton

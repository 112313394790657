import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import Link from '../Link/GatsbyLink'
import UserMenu from '../UserMenu'

// Local components
import NavBarLogo from './NavBarLogo'
import NavBarMenu from './NavBarMenu'
import NavBarMobileButton from './NavBarMobileButton'
import NavBarMobileLogo from './NavBarMobileLogo'
import NavBarMobileMenu from './NavBarMobileMenu'

// Utils
import ROUTES from '../../config/routes'
import useAuth from '../../state/auth/useAuth'

const NavBar = ({ leftItems, navClassnames, navChildClassnames, menuClassnames }) => {
  const { t } = useTranslation('common')

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  const { isLoaded, isGuest } = useAuth()

  return (
    <nav
      className={classnames(navClassnames, {
        'border-b border-gray-100 bg-white': true,
      })}
    >
      <div className={classnames('mx-auto px-2 sm:px-6 lg:px-8', navChildClassnames)}>
        <div className={classnames('relative flex items-center justify-between', menuClassnames)}>
          {leftItems && (
            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
              <NavBarMobileButton onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} />
            </div>
          )}

          <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
            <div className="shrink-0 flex items-center">
              <NavBarLogo />
              <NavBarMobileLogo />
            </div>

            {leftItems && (
              <div className="hidden sm:block sm:ml-6 sm:mt-1">
                <NavBarMenu items={leftItems} />
              </div>
            )}
          </div>

          {isLoaded && (
            <div className={'absolute inset-y-0 right-0 flex items-center'}>
              {isGuest ? (
                <div className={'hidden sm:block'}>
                  <Link to={ROUTES.signin} className={'btn btn-sm btn-primary-outline mr-2'}>
                    {t('navbar.loginLink')}
                  </Link>

                  <Link to={ROUTES.signup} className={'btn btn-sm btn-primary'}>
                    {t('navbar.signupLink')}
                  </Link>
                </div>
              ) : (
                <UserMenu
                  items={[
                    {
                      type: 'profile',
                    },
                    {
                      href: ROUTES.dashboard,
                      label: t('navbar.userMenu.dashboard'),
                    },
                    {
                      type: 'divider',
                    },
                    {
                      type: 'logout',
                    },
                  ]}
                />
              )}
            </div>
          )}
        </div>
      </div>

      {leftItems && (
        <div
          className={classnames({
            'slide-down': isMobileMenuOpen,
            'slide-up': isMobileMenuOpen === false,
          })}
        >
          <NavBarMobileMenu items={leftItems} />
        </div>
      )}
    </nav>
  )
}

NavBar.propTypes = {
  navClassnames: PropTypes.any,
  navChildClassnames: PropTypes.any,
  menuClassnames: PropTypes.any,

  leftItems: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
}

export default NavBar

import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'

const TextArea = (props) => {
  return (
    <TextareaAutosize
      onChange={props.onChange}
      value={props.value}
      placeholder={props.placeholder}
      disabled={props.disabled}
      required={props.required}
      className={props.className}
      minRows={props.minRows}
    />
  )
}

export default TextArea

import React from 'react'

const Checkbox = (props) => {
  return (
    <input
      onChange={props.onChange}
      value={props.value}
      checked={props.checked}
      type={'checkbox'}
      disabled={props.disabled}
      required={props.required}
      className={props.className}
    />
  )
}

export default Checkbox

import React from 'react'
import classnames from 'classnames'

const InputRow = ({ isInline, className, children }) => {
  return (
    <div
      className={classnames(
        {
          'flex flex-col': isInline !== true,
        },
        className,
      )}
    >
      {children}
    </div>
  )
}

export default InputRow

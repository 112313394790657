import React from 'react'
import styled, { keyframes, css } from 'styled-components'

const strike = keyframes`
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
`

const animationRule = css`
  animation-name: ${(props) => (props.isAnimated ? strike : 'none')};
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`

const StrikedSpan = styled.span`
  display: inline-block;
  position: relative;

  &:before {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 8px;
    background: var(--colors-red-500);

    ${animationRule}
  }
`

export default function LineThroughLabel({ isAnimated, children }) {
  return <StrikedSpan isAnimated={isAnimated}>{children}</StrikedSpan>
}

import React from 'react'
import classnames from 'classnames'

export default function MarkerLabel({
  children,
  isDanger,
  isSuccess,
  isInfo,
  isWarning,
  classNames,
}) {
  return (
    <span
      className={classnames(
        'px-2 py-1 font-medium',
        {
          'bg-red-100': isDanger,
          'bg-green-100': isSuccess,
          'bg-blue-100': isInfo,
          'bg-orange-100': isWarning,
        },
        classNames,
      )}
    >
      {children}
    </span>
  )
}

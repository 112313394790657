import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type LoadingButtonProps = {
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  disabled?: boolean
  submitting?: boolean
  className?: string
  label?: React.ReactNode
  children?: React.ReactNode
  type?: 'button' | 'submit' | 'reset'
}

const LoadingButton = ({
  onClick,
  submitting,
  disabled,
  className,
  label,
  children,
  type,
}: LoadingButtonProps) => {
  return (
    <button
      type={type ? type : 'button'}
      onClick={onClick}
      disabled={submitting || disabled}
      className={className}
    >
      {submitting === true && (
        <FontAwesomeIcon
          className={'mr-2 inline-block relative'}
          spin={true}
          icon={['fad', 'spinner-third']}
        />
      )}

      {label ? label : children}
    </button>
  )
}

LoadingButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  submitting: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.any,
  children: PropTypes.any,
  type: PropTypes.string,
}

export default LoadingButton
